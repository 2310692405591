
















































import { computed, defineComponent, ref } from '@vue/composition-api';

const CHANNEL_URL = 'https://discord.com/channels/688863898686062675';

export default defineComponent({
  name: 'MDiscordChannelRedirection',
  props: {
    discordData: {
      require: true,
      type: Object,
      default: () => {}
    }
  },
  setup(props: any, ctx: any) {
    const discordDataValue = computed({
      get: () => props.discordData,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const isEmployerChannel = ref(false);
    const isInternsChannel = ref(false);
    async function redirectToEmployerChannel() {
      isEmployerChannel.value = true;
      setTimeout(() => {
        window.open(`${CHANNEL_URL}/${discordDataValue?.value?.channel.id}`, '_blank');
        isEmployerChannel.value = false;
      }, 2000);
    }
    async function redirectToInternsChannel() {
      isInternsChannel.value = true;
      const currentInternsChannelId = '1006082509592862760';
      setTimeout(() => {
        window.open(`${CHANNEL_URL}/${currentInternsChannelId}`, '_blank');
        isInternsChannel.value = false;
      }, 2000);
    }

    return {
      discordDataValue,
      isEmployerChannel,
      isInternsChannel,
      redirectToEmployerChannel,
      redirectToInternsChannel
    };
  }
});
